<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="BookIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ $t('Area Name') }}</span>
          </b-card-title>
        </b-card-header>
        <div class="m-2">
          <b-row>
            <b-col cols="6">
              <b-form-group
                :label="$t('Name')"
                label-for="name"
              >
                <b-form-input
                  v-model="name"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Name')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('Service Characteristics')"
                    label-for="serviceCharacteristics"
                  >
                    <v-select
                      id="serviceCharacteristics"
                      v-model="serviceCharacteristics"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="OptionServiceCharacteristics"
                      :reduce="(OptionServiceCharacteristics) => OptionServiceCharacteristics.value"
                      class="select-size-lg"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="serviceCharacteristics === 'in_the_radius'"
                  cols="6"
                >
                  <b-form-group
                    :label="$t('Set Radius')"
                    label-for="setRadius"
                  >
                    <b-form-input
                      v-model="setRadius"
                      class="d-inline-block mr-1"
                      :placeholder="$t('Set Radius')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card
        no-body
        class="mb-2"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="GlobeIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ $t('Area') }}</span>
          </b-card-title>
        </b-card-header>
        <div class="m-2">
          <b-row>
            <b-col cols="4">
              <b-form-group
                :label="$t('Province')"
                label-for="provinceId"
              >
                <v-select
                  id="provinceId"
                  v-model="provinceId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="provinceData"
                  :reduce="(provinceData) => provinceData"
                  class="select-size-lg"
                  @input="inputAddress($event, 'province', true)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="$t('District')"
                label-for="districtId"
              >
                <v-select
                  id="districtId"
                  v-model="districtId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="districtData"
                  :reduce="(districtData) => districtData"
                  class="select-size-lg"
                  @input="inputAddress($event, 'district', true)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="$t('Subdistrict')"
                label-for="subDistrictId"
              >
                <v-select
                  id="subDistrictId"
                  v-model="subDistrictId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="subDistrictData"
                  :reduce="(subDistrictData) => subDistrictData"
                  class="select-size-lg"
                  @input="inputAddress($event, 'subDistrict', false)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card
        no-body
        class="mb-2"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="MapIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ $t('Map') }}</span>
          </b-card-title>
        </b-card-header>
        <div class="m-2">
          <b-row class="mb-2">
            <b-col cols="6">
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('Latitude')"
                    label-for="latitude"
                  >
                    <b-form-input
                      v-model="dataCenterLat"
                      :disabled="!moveCenter"
                      class="d-inline-block mr-1"
                      :placeholder="$t('Latitude')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('Longitude')"
                    label-for="longitude"
                  >
                    <b-form-input
                      v-model="dataCenterLng"
                      :disabled="!moveCenter"
                      class="d-inline-block mr-1"
                      :placeholder="$t('Longitude')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="6">
                  <b-form-checkbox
                    v-model="moveCenter"
                    :disabled="drawPolygonStatus"
                    class="mt-2"
                    switch
                  >
                    {{ $t('Change Center') }}
                  </b-form-checkbox>
                </b-col>
                <b-col cols="6">
                  <b-form-checkbox
                    v-model="drawPolygonStatus"
                    :disabled="moveCenter"
                    class="mt-2"
                    switch
                  >
                    {{ $t('Draw Polygon') }}
                  </b-form-checkbox>
                  <b-button
                    v-if="drawPolygonStatus"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="d-flex"
                    @click="clearPolygon"
                  >
                    <feather-icon
                      icon="RefreshCcwIcon"
                      class="mr-1"
                    />
                    <span class="align-middle">{{ $t('Clear Polygon') }}</span>
                  </b-button>
                </b-col>
              </b-row>

            </b-col>
          </b-row>
          <GmapMap
            ref="mapRef"
            :style="'height:500px'"
            :center="dataCenter"
            :zoom="zoom"
            @click="drawInMap"
          >
            <GmapMarker
              :draggable="draggable"
              :position="dataCenter"
              :clickable="true"
              @dragend="setLocal"
            />
            <GmapPolygon
              :editable="drawPolygonStatus"
              :paths="pathPolygon"
              @paths_changed="updatePathPolygon($event)"
              @click="drawInMap"
              @rightclick="rightClickPolygon"
            />
          </GmapMap>
        </div>
      </b-card>
      <b-card
        no-body
        class="mb-0"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="EditIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ $t('Action') }}</span>
          </b-card-title>
        </b-card-header>
        <div class="m-2">
          <b-row>
            <b-col cols="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="saveData"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-1"
                />
                <span class="align-middle">{{ $t('Save') }}</span>
              </b-button>
            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import storeModule from '@/store/services/hero/serviceArea'
import vSelect from 'vue-select'

const STORE_MODULE_NAME = 'serviceArea'
export default {
  components: {
    BCardHeader,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BOverlay,
    vSelect,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      draggable: false,
      moveCenter: true,
      drawPolygonStatus: false,
      pathPolygon: [],
      name: '',
      serviceCharacteristics: '',
      setRadius: '5',
      provinceId: '',
      districtId: '',
      subDistrictId: '',
      zoom: 12,
    }
  },
  computed: {
    OptionServiceCharacteristics() {
      return [
        { name: this.$t('In The Radius'.toUpperCase()), value: 'in_the_radius' },
        { name: this.$t('In The Area'.toUpperCase()), value: 'in_the_area' },
      ]
    },
    dataCenter() {
      return store.state[STORE_MODULE_NAME].centerData
    },
    // name: {
    //   get() {
    //     return store.state[STORE_MODULE_NAME].name
    //   },
    //   // eslint-disable-next-line no-unused-vars
    //   set(value) {
    //     store.commit(`${STORE_MODULE_NAME}/UPDATE_NAME_LOCATION`, value)
    //   },
    // },
    dataCenterLat: {
      get() {
        return store.state[STORE_MODULE_NAME].centerData.lat
      },
      // eslint-disable-next-line no-unused-vars
      set(value) {
        const lat = parseFloat(value)
        store.commit(`${STORE_MODULE_NAME}/UPDATE_CENTER_LOCATION_LAT`, lat)
        this.$refs.mapRef.$mapPromise.then(map => {
          map.panTo({ lat, lng: this.dataCenterLng })
        })
      },
    },
    dataCenterLng: {
      get() {
        return store.state[STORE_MODULE_NAME].centerData.lng
      },
      // eslint-disable-next-line no-unused-vars
      set(value) {
        const lng = parseFloat(value)
        store.commit(`${STORE_MODULE_NAME}/UPDATE_CENTER_LOCATION_LNG`, lng)
        this.$refs.mapRef.$mapPromise.then(map => {
          map.panTo({ lat: this.dataCenterLat, lng })
        })
      },
    },
    provinceData() {
      if (store.state[STORE_MODULE_NAME].provinceData !== null) {
        return this.formatData(store.state[STORE_MODULE_NAME].provinceData.data)
      }
      return []
    },
    districtData() {
      if (store.state[STORE_MODULE_NAME].districtData !== null) {
        return this.formatData(store.state[STORE_MODULE_NAME].districtData.data)
      }
      return []
    },
    subDistrictData() {
      if (store.state[STORE_MODULE_NAME].subDistrictData !== null) {
        return this.formatData(store.state[STORE_MODULE_NAME].subDistrictData.data)
      }
      return []
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    this.provinceGet()
    store.commit(`${STORE_MODULE_NAME}/UPDATE_NAME_LOCATION`, '')

    if (this.$route.params.id !== 'add') {
      const obj = {
        id: this.$route.params.id,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const respData = result.data.data
          this.selectedData(respData)
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    clearPolygon() {
      this.pathPolygon = []
    },
    setLocal(location) {
      // ลากแล้วเปลี่ยน center
      const lat = location.latLng.lat()
      const lng = location.latLng.lng()
      if (this.moveCenter) {
        store.commit(`${STORE_MODULE_NAME}/UPDATE_CENTER_LOCATION`, { lat, lng })
      }
    },
    updatePathPolygon(mvcArray) {
      const polygonArray = []

      for (let i = 0; i < mvcArray.getLength(); i += 1) {
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j += 1) {
          const lat = mvcArray
            .getAt(i)
            .getAt(j)
            .lat()
          const lng = mvcArray
            .getAt(i)
            .getAt(j)
            .lng()
          polygonArray.push({ lat, lng })
        }
      }

      this.pathPolygon = polygonArray
      console.log(this.pathPolygon)
    },
    // eslint-disable-next-line no-unused-vars
    rightClickPolygon(location) {},
    drawInMap(location) {
      // กดแล้วเปลี่ยน center
      const lat = location.latLng.lat()
      const lng = location.latLng.lng()
      if (this.drawPolygonStatus) {
        const polygonPoint = { lat, lng }
        this.pathPolygon.push(polygonPoint)
      } else {
        if (this.moveCenter) {
          store.commit(`${STORE_MODULE_NAME}/UPDATE_CENTER_LOCATION`, { lat, lng })
        }
        this.$refs.mapRef.$mapPromise.then(map => {
          map.panTo({ lat, lng })
        })
      }
    },
    selectedData(data) {
      if (data.name) {
        this.name = data.name
      }
      // เลิือกพวก address ถ้าเกิดมีอยู่แล้ว

      const indexProvince = data.provinceId.name.findIndex(e => e.lang === this.$i18n.locale)
      if (indexProvince > -1) {
        this.provinceId = {
          _id: data.provinceId._id,
          id: data.provinceId.id,
          name: data.provinceId.name[indexProvince].value,
        }
        this.inputAddress(this.provinceId, 'province', false)
      }

      const indexDistrict = data.districtId.name.findIndex(e => e.lang === this.$i18n.locale)
      if (indexDistrict > -1) {
        this.districtId = {
          _id: data.districtId._id,
          id: data.districtId.id,
          name: data.districtId.name[indexDistrict].value,
        }
        this.inputAddress(this.districtId, 'district', false)
      }

      const indexSubDistrict = data.subDistrictId.name.findIndex(e => e.lang === this.$i18n.locale)
      if (indexSubDistrict > -1) {
        this.subDistrictId = {
          _id: data.subDistrictId._id,
          id: data.subDistrictId.id,
          name: data.subDistrictId.name[indexSubDistrict].value,
          latitude: data.subDistrictId.latitude,
          longitude: data.subDistrictId.longitude,
        }
      }
      if (data.serviceCharacteristics) {
        this.serviceCharacteristics = data.serviceCharacteristics
      }
      if (data.setRadius) {
        this.setRadius = data.setRadius
      }
      // เอา centerLocation มาแสดง (จุดกลาง)

      if (data.centerLocation.coordinates.length === 2) {
        const lat = parseFloat(data.centerLocation.coordinates[1])
        const lng = parseFloat(data.centerLocation.coordinates[0])
        store.commit(`${STORE_MODULE_NAME}/UPDATE_CENTER_LOCATION`, { lat, lng })
        this.$refs.mapRef.$mapPromise.then(map => {
          map.panTo({ lat, lng })
        })
      }
      // เอา location มาแสดง (พื้นที่)
      if (data.location.coordinates.length > 0) {
        if (data.location.coordinates[0].length > 0) {
          const pathPolygon = data.location.coordinates[0]
          pathPolygon.slice(data.location.coordinates[0].length - 1, 1)
          this.pathPolygon = pathPolygon.map(e => ({
            lng: e[0],
            lat: e[1],
          }))
        }
      }
    },
    formatData(data) {
      // จัดรูปแบบข้อมูล

      return data.map(e => {
        const indexLang = e.name.findIndex(index => index.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return {
            _id: e._id,
            id: e.id,
            name: e.name[indexLang].value,
            latitude: e.latitude,
            longitude: e.longitude,
          }
        }
        return {
          _id: e._id,
          id: e.id,
          name: e.name[0].value,
          latitude: e.latitude,
          longitude: e.longitude,
        }
      })
    },
    showFromCurrentLanguage(data) {
      // เปลี่ยนภาษาตามเว็บ
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    inputAddress(input, type, clear) {
      // เลือกพวก address แล้วให้มันโหลดมา
      if (type === 'province') {
        this.show = true

        store
          .dispatch(`${STORE_MODULE_NAME}/district`, { id: input.id })
        // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.show = false
            if (clear) {
              this.districtId = ''
            }
            // console.log('fetch Success : ', result)
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
          }).catch(error => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      } else if (type === 'district') {
        this.show = true

        store
          .dispatch(`${STORE_MODULE_NAME}/subDistrict`, { id: input.id })
        // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.show = false
            if (clear) {
              this.subDistrictId = ''
            }
            // console.log('fetch Success : ', result)
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
          }).catch(error => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      } else if (type === 'subDistrict') {
        const lat = parseFloat(input.latitude)
        const lng = parseFloat(input.longitude)
        if (this.moveCenter) {
          store.commit(`${STORE_MODULE_NAME}/UPDATE_CENTER_LOCATION`, { lat, lng })
        }
        this.$refs.mapRef.$mapPromise.then(map => {
          map.panTo({ lat, lng })
        })
      }
    },
    provinceGet() {
      // รับค่าจังหวัด

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/province`, {})
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    saveData() {
      if (this.name.trim() === '') {
        this.show = false

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t('PLEASE_INPUT_NAME'),
          },
        })
        return
      }
      if (this.serviceCharacteristics.trim() === '') {
        this.show = false

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t('PLEASE_INPUT_SERVICE_CHARACTERISTICS'),
          },
        })
        return
      }
      if (this.provinceId === '') {
        this.show = false

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t('PLEASE_SELECT_PROVINCE'),
          },
        })
        return
      }
      if (this.districtId === '') {
        this.show = false

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t('PLEASE_SELECT_DISTRICT'),
          },
        })
        return
      }
      if (this.subDistrictId === '') {
        this.show = false

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t('PLEASE_SELECT_SUBDISTRICT'),
          },
        })
        return
      }

      // บันทึก
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            const obj = {
              id: this.$route.params.id === 'add' ? null : this.$route.params.id,
              name: this.name,
              centerLocation: {
                type: 'Point',
                coordinates: [store.state[STORE_MODULE_NAME].centerData.lng, store.state[STORE_MODULE_NAME].centerData.lat],
              },
              location: {
                type: 'Polygon',
                coordinates: this.pathPolygon.length > 0 ? [this.pathPolygon.map(e => [e.lng, e.lat])] : [],
              },
              provinceId: this.provinceId._id,
              districtId: this.districtId._id,
              subDistrictId: this.subDistrictId._id,
              serviceCharacteristics: this.serviceCharacteristics,
              setRadius: this.setRadius,
            }
            if (obj.id !== null) {
              this.show = true

              store
                .dispatch(`${STORE_MODULE_NAME}/update`, obj)
                .then(result => {
                  console.log('fetch Success : ', result)
                  this.show = false

                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Success'),
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: this.$t('Success'),
                    },
                  })
                }).catch(error => {
                  console.log('fetchUsers Error : ', error)
                  this.show = false

                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            } else {
              this.show = true

              store
                .dispatch(`${STORE_MODULE_NAME}/add`, obj)
                .then(result => {
                  console.log('fetch Success : ', result)
                  this.show = false

                  this.$router.push({ path: '/hero/service/area' })

                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Success'),
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: this.$t('Success'),
                    },
                  })
                }).catch(error => {
                  console.log('fetchUsers Error : ', error)
                  this.show = false

                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            }
          }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
